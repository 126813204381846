<template>
  <div class="mt-5">

    <p class="lead">Produtos Cadastrados</p>

    <div class="d-flex flex-column">
      <b-button v-on:click="uploadProducts" variant="primary" class="w-50">Atualizar no servidor</b-button>

      <b-button variant="primary" class="mt-2 w-50" v-b-modal.modal>
        + Adicionar produto
      </b-button>
    </div>
    <b-modal
        id="modal"
        ref="modal"
        :title="selectedProduct.id ? 'Editar - ' + (selectedProduct.nome || '') : 'Novo Produto'"
        @show="clearForm"
        @hidden="clearForm"
        @ok="handleOk"
        @cancel="handleCancel"
        size="lg"

    >
      <form ref="form" @submit.stop.prevent="handleSubmit()">

        <div class="row">
          <!-- NOME -->
          <b-form-group
              :state="state.nome"
              label="Nome"
              label-for="nome"
              invalid-feedback="Nome é obrigatório"
              class="col-12 col-md-6"
          >
            <b-form-input
                id="nome"
                v-model="selectedProduct.nome"
                :state="state.nome"
                v-on:change="setUrlName(selectedProduct.nome)"
                required
            >
            </b-form-input>
          </b-form-group>

          <!-- DESCRICAO -->
          <b-form-group
              :state="state.descricao"
              label="Descricação"
              label-for="descricao"
              invalid-feedback="Descricação é obrigatório"
              class="col-12 col-md-6"
          >
            <b-form-input
                id="descricao"
                v-model="selectedProduct.descricao"
                :state="state.descricao"
                required
            >
            </b-form-input>
          </b-form-group>
        </div>

        <div class="row">
          <!-- SEGMENTO -->
          <b-form-group
              :state="state.id"
              label="Segmento"
              label-for="segmento"
              invalid-feedback="Segmento é obrigatório"
              class="col-12 col-md-6"
          >
            <b-form-select
                id="segmento"
                v-model="selectedProduct.segmento"
                v-on:change="selectSegmentDescription(formulario.segmento)"
                :state="state.segmento"
                required
            >
              <b-form-select-option value="null" disabled>Escolha o segmento...</b-form-select-option>
              <b-form-select-option value="automobilistico">Automobilístico</b-form-select-option>
              <b-form-select-option value="cosmetico">Cosmético</b-form-select-option>
              <b-form-select-option value="farmaceutico">Farmacêutico</b-form-select-option>
              <b-form-select-option value="quimico">Químico</b-form-select-option>
              <b-form-select-option value="galao_5000ml">Galão 5000ml</b-form-select-option>
            </b-form-select>
          </b-form-group>

          <!-- DESCRIÇÃO SEGMENTO -->
          <b-form-group
              :state="state.descricao_segmento"
              label="Descrição do segmento"
              label-for="descricao_segmento"
              invalid-feedback="Descrição do segmento é obrigatório"
              class="col-12 col-md-6"
          >
            <b-form-input
                id="descricao_segmento"
                v-model="selectedProduct.descricao_segmento"
                :state="state.descricao_segmento"
                type="text"
                disabled
                required
            >
            </b-form-input>
          </b-form-group>

          <!-- TAMANHO -->
          <b-form-group
              :state="state.tamanho"
              label="Tamanho"
              label-for="tamanho"
              invalid-feedback="Tamanho é obrigatório"
              class="col-12 col-md-6"
          >
            <b-form-input
                id="tamanho"
                type="number"
                min="1"
                v-model="selectedProduct.tamanho"
                :state="state.tamanho"
                required
            >
            </b-form-input>
          </b-form-group>

          <!-- UNIDADE MEDIDA -->
          <b-form-group
              :state="state.unidade_medida"
              label="Unidade de medida"
              label-for="unidade_medida"
              invalid-feedback="Unidade de medida é obrigatória"
              class="col-12 col-md-6"
          >
            <b-form-select
                id="unidade_medida"
                v-model="selectedProduct.unidade_medida"
                :state="state.unidade_medida"
                required
            >
              <b-form-select-option value="null" disabled>Escolha a unidade de medida...</b-form-select-option>
              <b-form-select-option value="ml">Militros</b-form-select-option>
              <b-form-select-option value="g">Gramas</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>

        <div class="row">
          <!-- FOTO -->
          <b-form-group
              :state="state.nova_foto"
              label="Foto"
              label-for="foto"
              invalid-feedback="Foto é obrigatória"
              class="col-12 col-md-6"
          >
            <b-form-file
                id="foto"
                v-model="selectedProduct.nova_foto"
                :state="state.nova_foto"
                v-on:change="setImageName"
                accept="image/*"
                placeholder="Escolha imagem..."
                required
            >
            </b-form-file>
          </b-form-group>

          <!-- NOME FOTO -->
          <b-form-group
              :state="state.nome_foto"
              label="Nome da foto"
              label-for="nome_foto"
              invalid-feedback="Nome da foto é obrigatória"
              class="col-12 col-md-6"
          >
            <b-form-input
                id="foto"
                v-model="selectedProduct.nome_foto"
                :state="state.url_foto"
                required
            >
            </b-form-input>
          </b-form-group>

          <!-- URL PRODUTO -->
          <b-form-group
              :state="state.url"
              label="URL do produto"
              label-for="url"
              invalid-feedback="URL do produto é obrigatória"
              class="col-12 col-md-6"
          >
            <b-form-input
                id="url"
                v-model="selectedProduct.url"
                :state="state.url"
                required
            >
            </b-form-input>
          </b-form-group>
        </div>

        <h5 class="mt-3">Detalhes</h5>
        <hr>

        <div class="row">
          <!-- DETALHES - CARACTERISTICA -->
          <b-form-group
              :state="state.detalhes.caracteristica"
              label="Característica"
              label-for="caracteristica"
              invalid-feedback="Característica é obrigatória"
              class="col-12"
          >
            <b-form-textarea
                id="caracteristica"
                v-model="selectedProduct.detalhes.caracteristica"
                :state="state.detalhes.caracteristica"
                required
            >
            </b-form-textarea>
          </b-form-group>

          <!-- DETALHES - APLICACAO -->
          <b-form-group
              :state="state.detalhes.aplicacao"
              label="Aplicação"
              label-for="aplicacao"
              invalid-feedback="Aplicação é obrigatória"
              class="col-12"
          >
            <b-form-textarea
                id="aplicacao"
                v-model="selectedProduct.detalhes.aplicacao"
                :state="state.detalhes.aplicacao"
                required
            >
            </b-form-textarea>
          </b-form-group>

          <!-- DETALHES - TECNICO -->
          <b-form-group
              :state="state.detalhes.tecnico"
              label="Detalhe técnico"
              label-for="tecnico"
              invalid-feedback="Detalhe técnico é obrigatório"
              class="col-12"
          >
            <b-form-textarea
                id="tecnico"
                v-model="selectedProduct.detalhes.tecnico"
                :state="state.detalhes.tecnico"
                required
            >
            </b-form-textarea>
          </b-form-group>
        </div>

      </form>

      <template v-slot:modal-ok> Enviar </template>

      <template v-slot:modal-cancel> Cancelar </template>
    </b-modal>

    <b-alert
        :show="this.updatedProducts && this.state.updated.products"
        class="position-fixed fixed-bottom m-0 rounded-0"
        style="z-index: 2000; background-color: #152b5e"
        variant="border-0 text-light"
    >
      Produtos atualizados!
    </b-alert>

    <b-alert
        :show="!this.updatedProducts && this.state.updated.products"
        class="position-fixed fixed-bottom m-0 rounded-0"
        style="z-index: 2000; background-color: #bf0f11"
        variant="border-0 text-light"
    >
      Não foi possível atualizar os produtos!
    </b-alert>

    <b-table :items="produtos" :fields="fields" class="mt-5">
      <template #cell(Detalhes)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? 'Ocultar' : 'Mostrar'}}
        </b-button>
        <b-button size="sm" @click="editProduct(row)" class="mr-2" v-b-modal.modal>
          Editar
        </b-button>
        <b-button size="sm" @click="deleteProduct(row)" class="mr-2 btn-danger">
          Excluir
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Descrição:</b></b-col><b-col>{{ row.item.descricao }}</b-col></b-row>
          <b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Descrição Segmento:</b></b-col><b-col>{{ row.item.descricao_segmento }}</b-col></b-row>
          <b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Tamanho:</b></b-col><b-col>{{ row.item.tamanho }}</b-col></b-row>
          <b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Unidade de Medida:</b></b-col><b-col>{{ row.item.unidade_medida }}</b-col></b-row>
          <b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Url:</b></b-col><b-col>{{ row.item.url }}</b-col></b-row>
          <hr>
          <h5>Detalhes:</h5>
          <b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Caraterística:</b></b-col><b-col>{{ row.item.detalhes.caracteristica }}</b-col></b-row>
          <b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Aplicação:</b></b-col><b-col>{{ row.item.detalhes.aplicacao }}</b-col></b-row>
          <b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Técnico:</b></b-col><b-col>{{ row.item.detalhes.tecnico }}</b-col></b-row>

          <b-button size="sm" @click="row.toggleDetails">Ocultar Detalhes</b-button>
        </b-card>
      </template>
    </b-table>

  </div>
</template>

<script>

import firebase from 'firebase/app';

//TODO Atualizar imagem
//TODO Retornar status do upload do arquivo
//TODO Atualizar lista de produtos depois de feito o upload
//TODO Colocar validação no formulario

export default {
  name: "Administracao",
  data() {
    return {
      state: {
        id: null,
        repetido: null,
        segmento: null,
        descricao_segmento: null,
        tamanho: null,
        unidade_medida: null,
        nome: null,
        descricao: null,
        arquivo: null,
        nova_foto: null,
        url: null,
        detalhes: {
          caracteristica: null,
          aplicacao: null,
          tecnico: null
        },
        dirty:{
          nome_foto: false
        },
        updated:{
          products: false
        }
      },
      formulario: {
        id: null,
        repetido: null,
        segmento: null,
        descricao_segmento: null,
        tamanho: null,
        unidade_medida: null,
        nome: null,
        descricao: null,
        arquivo: null,
        nova_foto: null,
        url: null,
        detalhes: {
          caracteristica: null,
          aplicacao: null,
          tecnico: null
        }
      },
      segmento: null,
      updatedProducts: false,
      produtos: null,
      filter: "Galao",
      fields: ['id', 'nome', 'segmento', 'Detalhes'],
      selectedProduct: {"detalhes": {}},
      images: []
    }
  },
  created() {
    this.getProducts();
  },
  methods: {
    handleSubmit(){

      let isNewProduct = !this.selectedProduct.id;

      if(isNewProduct){
        this.validateForm(this.selectedProduct);
        this.selectedProduct.id = this.getMaxId(this.produtos) + 1;
        if(this.state.dirty.nome_foto){
          this.selectedProduct.arquivo = this.selectedProduct.nome_foto;
          this.uploadProductImage();
        }
        this.produtos.push(this.selectedProduct);

        return;
      }

      this.produtos = this.produtos.map(product => {
        if(product.id == this.selectedProduct.id){
            this.validateForm(this.selectedProduct);
            product = { ...this.selectedProduct };
            if(this.state.dirty.nome_foto){
              product.arquivo = this.selectedProduct.nome_foto;
              this.uploadProductImage();
            }
            return product;
        }
        return product;
      });
    },
    handleOk(modalEvent){
      try {
        this.handleSubmit();
      }catch (err){
        //TODO Mostrar erro na tela
        modalEvent.preventDefault();
      }

    },
    handleCancel(){
    },
    clearForm(){},
    clearState(){
      this.state.descricao = null;
    },
    selectSegmentDescription(segment){

      let descriptions = {
        "automobilistico": "Automobilística",
        "cosmetico": "Cosmética",
        "farmaceutico": "Farmacêutica",
        "quimico": "Química",
        "galao_5000ml": "Galão 5000ml"
      }

      this.setSegmentDescription(descriptions[segment] ?? '');
    },
    setSegmentDescription(segmentDescription){
      this.formulario.descricao_segmento = segmentDescription;
    },
    createSlugName(productName){
      let slug = this.removeAccents(productName.trim().replaceAll(' ', '-').toLocaleLowerCase());
      return slug;
    },
    setUrlName(productName){
      this.selectedProduct.url = this.createSlugName(productName);
    },
    setImageName(event){
      const files = event.target.files;
      this.selectedProduct.nome_foto = this.createSlugName(files[0].name);
      this.setDirtyNomeFoto();
    },
    removeAccents(string){
      let map = {
        'a' : 'á|à|ã|â|À|Á|Ã|Â',
        'e' : 'é|è|ê|É|È|Ê',
        'i' : 'í|ì|î|Í|Ì|Î',
        'o' : 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        'c' : 'ç|Ç',
        'n' : 'ñ|Ñ'
      }

      for (let pattern in map) {
        string = string.replace(new RegExp(map[pattern], 'g'), pattern);
      }

      return string;
    },
    getProducts(){
      firebase
          .storage()
          .ref('produtos.json')
          .getDownloadURL()
          .then((url) => {
            fetch(url)
                .then(res => res.json())
                .then((res) => this.produtos = res)
                .catch(() => this.produtos = null);
          })
    },
    editProduct(product){
      this.clearState();
      this.selectedProduct = {...product.item};
    },
    deleteProduct(product){
      this.produtos = this.produtos.filter(item => {
        return product.item.id != item.id;
      });
    },
    uploadProducts(){
      //TODO Adicionar validação para evitar que qq pessoa consiga fazer upload no storage
      //TODO Criar arquivo de backup antes da substituicao
      firebase.storage()
          .ref('produtos.json')
          .putString(JSON.stringify(this.produtos))
          .then(() => {
            this.updatedProducts = true;
            this.state.updated.products = true;
          })
          .catch(() => {
            console.log("ERRRO")
            this.updatedProducts = false;
            this.state.updated.products = true;
          })
          .finally(() => {
            setTimeout(() => {
              this.state.updated.products = false;
              this.updatedProducts = false;
            }, 5000);
          }
          );
    },
    uploadProductImage(){
      //TODO Reportar par ao usuario caso ocorra algum erro
      firebase
          .storage()
          .ref(`produtos/${this.selectedProduct.nome_foto}`)
          .put(this.selectedProduct.nova_foto)
          .then(res => res);
    },
    productUrlIsDuplicate(newProduct){
      if(this.produtos.filter(item => (item.url == newProduct.url && item.id != newProduct.id)).length){
        this.state.url = true;
        throw Error('URL DUPLICADA');
      }
    },
    imageNameIsDuplicate(newProduct){

      let imageName = newProduct.nome_foto;

      if(this.produtos.filter(item => (item.arquivo == imageName && item.id != newProduct.id)).length){
        this.state.nome_foto = true;
        throw Error('NOME DA FOTO DUPLICADA');
      }
    },
    validateForm(newProduct){

      this.productUrlIsDuplicate(newProduct);

      if(this.state.dirty.nome_foto){
        this.imageNameIsDuplicate(newProduct);
      }
    },
    getImageExtension(image){
      if(image && image.type){
        return image.type.replace('image/', '').toString();
      }
      return 'png';
    },
    setDirtyNomeFoto(){
      this.state.dirty.nome_foto = true;
    },
    getMaxId(arr){
      return arr.reduce(function (p, v) {
        return ( p.id > v.id ? p.id : v.id );
      });
    }
  }
}
</script>

<style scoped>

</style>